import React from "react";
import styled from "styled-components";
import ImgSlider from "./ImgSlider";
import Movies from "./Movies";
import Viewrs from "./Viewrs";
import DisneyPlus from "./DisneyPlus";
function Home() {
  

  // const [movie, setMovie] = React.useState()
  // React.useEffect(() => { // whenever i load the page make a requset to the data
  //   db.collection("movies").onSnapshot((snapshot) => {
  //     console.log(snapshot);
  //   })
  // }, [])
  

  return <Container>
    <ImgSlider />
    <Viewrs />
    <Movies />
  </Container>;
}

export default Home;

const Container = styled.div`
  min-height: calc(100vh - 70px);
  padding: 0 calc(3.5vw + 5px);
  position: relative;
  overflow-x: hidden;

  &::before {
    background: url("/images/home-background.png") center center / cover
      no-repeat fixed;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; // to make this behiend the rest of the code
  }
`;
